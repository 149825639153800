import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { RouterOutlet } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { CookiesService } from "./core/services/cookies.service";
import { LoaderService } from "./core/services/loader.service";
import { LANGUAGES_OPTIONS, DEFAULT_PEOPLE_MATE_LANGUAGE } from "./shared/constants/languages";
import { COOKIES_KEYS } from "./shared/constants/storage-keys";
import { Language } from "./shared/interfaces/language.interface";
import { SharedModule } from "./shared/modules/shared.module";
import { AuthService } from "./core/services/auth.service";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [SharedModule, CommonModule, RouterOutlet],
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit {
  // #region declare & init. variables
  langClass: string;
  languages: string[] = LANGUAGES_OPTIONS;
  selectedLang: Language = DEFAULT_PEOPLE_MATE_LANGUAGE;

  loaderService = inject(LoaderService);
  authService = inject(AuthService);
  isLoading = false;
  forceLoading = false;
  showLoader = false;
  // #endregion

  // #region constructor

  constructor(
    private translateService: TranslateService,
    private cookiesService: CookiesService,
    private titleService: Title,
    private cd: ChangeDetectorRef,
  ) {
    this.handleSettingDefaultLanguage();
    this.langClass = this.translateService.currentLang;
    this.authService.forceLoader$.next(this.authService.forceLoader);

    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langClass = event.lang;
    });
    this.setTitle();
  }
  ngAfterViewInit(): void {
    this.loaderService.requestCount$.subscribe(res => {
      this.isLoading = res > 0;
      this.showLoader = this.isLoading || this.forceLoading;
      this.cd.detectChanges();
    });
    this.authService.forceLoader$.subscribe(res => {
      this.forceLoading = res;
      this.showLoader = this.isLoading || this.forceLoading;
      this.cd.detectChanges();
    });
  }
  public setTitle() {
    const title = environment.TITLE;
    const favIcon = document.querySelector("#favIcon");
    (favIcon as HTMLLinkElement).href = environment.ICON;
    this.titleService.setTitle(title);
  }

  handleSettingDefaultLanguage() {
    this.translateService.addLangs(this.languages);

    if (this.cookiesService.check(COOKIES_KEYS.LANGUAGE_KEY)) {
      this.selectedLang = this.cookiesService.get(COOKIES_KEYS.LANGUAGE_KEY);
    } else {
      this.cookiesService.set(COOKIES_KEYS.LANGUAGE_KEY, this.selectedLang);
    }
    this.translateService.use(this.selectedLang.code);
  }

  // #endregion

  // #region ngOnInit

  moveScrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      // this.appStoreService.setLanguage(event.lang);
      // if (event.lang == "ar") {
      //   this.textDir = "rtl";
      // } else {
      //   this.textDir = "ltr";
      // }
    });
  }

  // #endregion
}
