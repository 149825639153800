import { Routes } from "@angular/router";

import { RoleGuard } from "./core/guards/role.guard";
import { AuthGuard } from "./core/guards/auth.guard";

import { EUSerRole } from "./shared/enums/user-roles.enum";

export const appRoutes: Routes = [
  {
    path: "",
    canActivate: [AuthGuard],
    loadComponent: () => import("./layouts/main/main.component"),
    loadChildren: () => import("./layouts/main/main.layout.routes"),
  },
  {
    path: "auth",
    loadComponent: () => import("./layouts/auth/auth.component"),
    loadChildren: () => import("./modules/authentication/authentication.routes"),
  },
  {
    path: "external",
    loadComponent: () => import("./layouts/external/external.component"),
    loadChildren: () => import("./layouts/external/external.layout.routes"),
  },
  {
    path: "setup-stepper",
    canActivate: [AuthGuard, RoleGuard],
    data: { roles: [EUSerRole.Admin] },
    loadComponent: () =>
      import("../app/modules/company-setup/pages/setup-stepper/setup-stepper.component"),
  },
  {
    path: "**",
    loadComponent: () =>
      import("./pages/not-found/not-found.component").then(page => page.NotFoundComponent),
  },
];
