import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({ providedIn: "root" })
export class NotificationMessageService {
  constructor(
    private snackBar: MatSnackBar,
    private zone: NgZone,
  ) {}

  showSuccessMessage(message: string) {
    this.snackBar.open(message, "×", {
      panelClass: ["success-message"],
      duration: 2000,
    });
  }

  showWarningMessage(message: any) {
    this.snackBar.open(message, "×", {
      panelClass: "warning-message",
      duration: 3000,
    });
  }

  // showInfoMessage(message: string, closable: boolean = false) {

  // }

  showErrorMessage(message: string) {
    this.snackBar.open(message, "×", {
      panelClass: ["error-message"],
      duration: 6000,
    });
  }
}

// .afterDismissed().subscribe((res) => {
// })
