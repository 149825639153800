import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { NotificationMessageService } from "./../services/notification.message.service";

@Injectable({ providedIn: "root" })
export class InternetInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationMessageService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!window.navigator.onLine) {
      this.notificationService.showErrorMessage(
        "No Internet, Make sure that you are connected to a network",
      );
      return throwError({ message: "no internet" });
    } else {
      return next.handle(request);
    }
  }
}
